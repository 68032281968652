import React from "react";
import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";

const ProgressChart = ({
  per1,
  per2,
  per3,
  per4,
  per5,
  per6,
  per7,
  per8,
  per9,
  per10,
}) => {
  // line chart
  //   const data = {
  //     labels: ["January", "February", "March", "April", "May"],
  //     datasets: [
  //       {
  //         label: "Progress",
  //         data: [10, 20, 30, 40, 50], // Update this array with your actual data
  //         fill: false,
  //         borderColor: "rgb(75, 192, 192)",
  //         tension: 0.1,
  //       },
  //     ],
  //   };
  //   const options = {
  //     scales: {
  //       y: {
  //         beginAtZero: true,
  //         max: 100, // Adjust the maximum value as per your requirements
  //       },
  //     },
  //   };

  const data = {
    labels: [
      "Timer 1",
      "Timer 2",
      "Timer 3",
      "Timer 4",
      "Timer 5",
      "Timer 6",
      "Timer 7",
      "Timer 8",
      "Timer 9",
      "Timer 10",
    ],
    datasets: [
      {
        label: "Progress",
        data: [per1, per2, per3, per4, per5, per6, per7, per8, per9, per10], // Update this array with your actual data
        backgroundColor: "rgba(138,43,226)",
      },
    ],
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        max: 100, // Adjust the maximum value as per your requirements
      },
      y: {
        beginAtZero: true,
      },
    },
    // backgroundColor: "rgba(0,0,0)",
  };

  return (
    <div className="bg-gray-100 rounded-lg p-2">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProgressChart;
